const middleware = {}

middleware['cache'] = require('../middleware/cache.js')
middleware['cache'] = middleware['cache'].default || middleware['cache']

middleware['exit'] = require('../middleware/exit.js')
middleware['exit'] = middleware['exit'].default || middleware['exit']

middleware['matomo'] = require('../middleware/matomo.js')
middleware['matomo'] = middleware['matomo'].default || middleware['matomo']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
