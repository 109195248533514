import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62cf62b7 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2203356c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _f3160f92 = () => interopDefault(import('../pages/design-services.vue' /* webpackChunkName: "pages/design-services" */))
const _444b0b80 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _34ab4954 = () => interopDefault(import('../pages/know-how.vue' /* webpackChunkName: "pages/know-how" */))
const _0b71b2ff = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _7a1f4b6e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d2703c3a = () => interopDefault(import('../pages/subscription.vue' /* webpackChunkName: "pages/subscription" */))
const _2c84fbb1 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _d4b79cea = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _064148fd = () => interopDefault(import('../pages/checkout/data.vue' /* webpackChunkName: "pages/checkout/data" */))
const _7a4c62ea = () => interopDefault(import('../pages/checkout/order.vue' /* webpackChunkName: "pages/checkout/order" */))
const _14d7effa = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _6806a28d = () => interopDefault(import('../pages/checkout/plan/data.vue' /* webpackChunkName: "pages/checkout/plan/data" */))
const _cc80b20a = () => interopDefault(import('../pages/checkout/plan/order.vue' /* webpackChunkName: "pages/checkout/plan/order" */))
const _ab34f71a = () => interopDefault(import('../pages/checkout/plan/payment.vue' /* webpackChunkName: "pages/checkout/plan/payment" */))
const _05fcdfcf = () => interopDefault(import('../pages/checkout/plan/welcome.vue' /* webpackChunkName: "pages/checkout/plan/welcome" */))
const _faf18508 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _217e4ef8 = () => interopDefault(import('../pages/checkout/download/_key.vue' /* webpackChunkName: "pages/checkout/download/_key" */))
const _efd9cb18 = () => interopDefault(import('../pages/diagrams/slides/_url.vue' /* webpackChunkName: "pages/diagrams/slides/_url" */))
const _35aa75d7 = () => interopDefault(import('../pages/catalog/_tag.vue' /* webpackChunkName: "pages/catalog/_tag" */))
const _464d4952 = () => interopDefault(import('../pages/category/_category.vue' /* webpackChunkName: "pages/category/_category" */))
const _4513b7ed = () => interopDefault(import('../pages/diagrams/_diagram.vue' /* webpackChunkName: "pages/diagrams/_diagram" */))
const _a4c91eaa = () => interopDefault(import('../pages/s/_query.vue' /* webpackChunkName: "pages/s/_query" */))
const _66f8b500 = () => interopDefault(import('../pages/slides/_url_title.vue' /* webpackChunkName: "pages/slides/_url_title" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _62cf62b7,
    name: "about"
  }, {
    path: "/contact",
    component: _2203356c,
    name: "contact"
  }, {
    path: "/design-services",
    component: _f3160f92,
    name: "design-services"
  }, {
    path: "/faq",
    component: _444b0b80,
    name: "faq"
  }, {
    path: "/know-how",
    component: _34ab4954,
    name: "know-how"
  }, {
    path: "/newsletter",
    component: _0b71b2ff,
    name: "newsletter"
  }, {
    path: "/search",
    component: _7a1f4b6e,
    name: "search"
  }, {
    path: "/subscription",
    component: _d2703c3a,
    name: "subscription"
  }, {
    path: "/terms",
    component: _2c84fbb1,
    name: "terms"
  }, {
    path: "/wishlist",
    component: _d4b79cea,
    name: "wishlist"
  }, {
    path: "/checkout/data",
    component: _064148fd,
    name: "checkout-data"
  }, {
    path: "/checkout/order",
    component: _7a4c62ea,
    name: "checkout-order"
  }, {
    path: "/checkout/payment",
    component: _14d7effa,
    name: "checkout-payment"
  }, {
    path: "/checkout/plan/data",
    component: _6806a28d,
    name: "checkout-plan-data"
  }, {
    path: "/checkout/plan/order",
    component: _cc80b20a,
    name: "checkout-plan-order"
  }, {
    path: "/checkout/plan/payment",
    component: _ab34f71a,
    name: "checkout-plan-payment"
  }, {
    path: "/checkout/plan/welcome",
    component: _05fcdfcf,
    name: "checkout-plan-welcome"
  }, {
    path: "/",
    component: _faf18508,
    name: "index"
  }, {
    path: "/checkout/download/:key?",
    component: _217e4ef8,
    name: "checkout-download-key"
  }, {
    path: "/diagrams/slides/:url?",
    component: _efd9cb18,
    name: "diagrams-slides-url"
  }, {
    path: "/catalog/:tag?",
    component: _35aa75d7,
    name: "catalog-tag"
  }, {
    path: "/category/:category?",
    component: _464d4952,
    name: "category-category"
  }, {
    path: "/diagrams/:diagram?",
    component: _4513b7ed,
    name: "diagrams-diagram"
  }, {
    path: "/s/:query?",
    component: _a4c91eaa,
    name: "s-query"
  }, {
    path: "/slides/:url_title?",
    component: _66f8b500,
    name: "slides-url_title"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
